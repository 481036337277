/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga";
import "./App.css";
import "./AccessbilityApp.css";
import Crouse from "./CrouseDetails/Crouse";
import { MapEventsContext, ColorContrast } from "./utils/contexts";
import Map from "./map/Map";
import ItemPopup from "./Popup/itemPopup";
import "./index.css";

import SignInCheck from "./Popup/SignInCheck";
import TermsPopup from "./Popup/TermsPopup";
import { useNavigate, useParams } from "react-router-dom";
import { useLoadImages } from "./hooks/useLoadImages";
import { useDownloadPdfs } from "./hooks/useDownloadPdfs";
import { getHelmetData } from ".";
import { ColorContrastButton } from "./ColorContrast/colorContrastButton.js";
import { Helmet } from "react-helmet";
import Loader from "./Loader";
import SkeletonLoader from "./CrouseDetails/SkeletonLoader";
import consts from "./consts";

function MobileView() {
  const backFocusRef = useRef();
  const { linkedBundleId, linkedCity, linkedLocation } = useParams();

  const [pages, setPages] = useState({});
  const [crouseData, setCrouseData] = useState(null);
  const [items, setItems] = useState([]);
  const [center, setCenter] = useState(null);
  const [dockingPoint, setDockingPoint] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [popUp, setPopup] = useState(null);
  const [isFromMap, setIsFromMap] = useState(false);
  const [isClickMoreInfo, setIsClickMoreInfo] = useState(false);
  const [TermsAgreed, setTermsAgreed] = useState(
    localStorage.getItem("terms-agreed")
  );
  const [selectCrousePopup, setSelectCrousePopup] = useState(true);
  const [bundleId, setBundleId] = useState(linkedBundleId);
  const [currentLocation, setCurrentLocation] = useState({
    latitude: 51.525,
    longitude: 7.4575,
  });
  const [message, setMessage] = useState(true);
  const [check, setCheck] = useState(true);
  const [passedCheck, setPassedCheck] = useState(false);
  const [allBundleData, setAllBundleData] = useState("");
  const [loader, setLoader] = useState(false);
  const [navigating, setNavigating] = useState(linkedBundleId);
  const [mapMode, setMapMode] = useState(false);
  const [isColorContrast, setIsColorContrast] = useState(false);
  const [isFromMapView, setIsFromMapView] = useState(false);
  const [closePopup, setClosePopup] = useState(undefined);
  const [selectedTab, setSelectedTab] = useState("");
  const { loading } = useLoadImages();
  const navigate = useNavigate();

  const trackingId = "G-3LYZ8HFVZ6";

  ReactGA.initialize(trackingId);

  const { pdfLoading, downloadPdfs, pdfDown } = useDownloadPdfs(
    pages,
    allBundleData?.clientId?.clientName,
    "",
    "."
  );

  //get data
  useEffect(() => {
    if (bundleId) {
      setLoader(!loader);

      fetch(consts.S3_URI)
        .then(async (response) => {
          let data = await response.json();
          data = data?.[0] || data;
          if (data?.pages) {
            setNavigating(data?.pages[0]?.city);
            navigate(`/${data?.bundleUUID || "all"}/${data?.pages[0]?.city}`);
            setAllBundleData({
              ...data,
              bundleUUID: data.bundleUUID || bundleId,
            });
            setNavigating(data?.pages[0]?.city);
            setAllBundleData({
              ...data,
              bundleUUID: data.bundleUUID || bundleId,
            });
            setPages(data.pages);
            setPassedCheck(true);
          } else {
            setNavigating(false);
            setCheck(false);
          }
          setMessage(!message);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
          setNavigating(false);
          setCheck(false);
          setMessage(!message);
          setLoader(false);
        });
    }
  }, [bundleId]);

  // open link city
  useEffect(() => {
    if (linkedCity && pages?.find) {
      setSelectedCrouse(linkedCity);
      // setSelectCrousePopup(false)
    }
  }, [pages, linkedCity]);

  //open link crouse
  useEffect(() => {
    if (crouseData && pages?.find) {
      setSelectCrousePopup(false);
      setNavigating(linkedLocation);
      if (bundleId === "all" || bundleId === "all-sorted") {
        if (!TermsAgreed) {
          setSelectCrousePopup(true);
          setPassedCheck(false);
        }
      }
    }
  }, [pages, linkedCity, crouseData]);

  //open link popup
  useEffect(() => {
    if (!popUp && items?.find && linkedLocation) {
      items.forEach((i) => {
        if (i.items) {
          const item = i.items.find((i) => i._id === linkedLocation);
          if (item) {
            setPopup(item);
            return;
          }
        }
      });
      setNavigating(selectCrousePopup ? "selectCrousePopup" : false);
    }
  }, [selectCrousePopup, linkedLocation, items]);

  //finish navigation
  useEffect(() => {
    if (navigating === "selectCrousePopup") setNavigating(false);
  }, [selectCrousePopup]);

  const setSelectedCrouse = (itemId) => {
    let page = pages.find(
      (i) => i._id === itemId || i.city === itemId || i.title === itemId
    );
    if (!page) {
      itemId = itemId.replace("-", " ");
      page = pages.find((i) => i.title === itemId || i.city === itemId);
    }

    if (page) {
      //merge the bookings with the types together.
      let items = [];
      if (page.types) {
        if (!page.bookings?.length) items = [...page.types];
        else {
          const splitItemsAfter = Math.floor(
            page.types.length / page.bookings.length
          );
          let start = 0;
          let promotionIndex = 1;
          const bookingsCopy = [...page.bookings];
          for (
            let i = splitItemsAfter;
            i <= page.types.length;
            i += splitItemsAfter
          ) {
            items.push(...page.types.slice(start, i));
            start = i;
            bookingsCopy.length &&
              items.push({
                ...bookingsCopy.shift(),
                promotionIndex: promotionIndex++,
              });
          }
        }
      }
      setCrouseData(page);
      setItems(items);
      console.log(
        page.startLocation?.coordinates,
        page.dockingPoint?.coordinates
      );
      setCenter(page.startLocation?.coordinates);
      setDockingPoint(page.dockingPoint?.coordinates);
    } else {
      setNavigating(false);
    }
  };

  //set map first point
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentLocation([
          position.coords.latitude,
          position.coords.longitude,
        ]);
      },
      () => {
        // alert("Position could not be determined.");
      }
    );
  }, []);

  // set back button
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      if (popUp) setPopup(null);
      else setMapMode(false);
      // window.history.go(1);
    };
  }, [popUp]);

  // scroll map
  useEffect(() => {
    if (selectedItem) {
      setCenter(selectedItem.location.coordinates);
    } else {
      setCenter(crouseData?.startLocation?.coordinates);
    }
  }, [selectedItem]);

  const isDesktop = false;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {getHelmetData()}
      {loading || navigating ? (
        <>
          <Loader />
          <SkeletonLoader />
        </>
      ) : (
        <div
          className={`crouse-app-container crouse-app-container-mobile ${
            window.innerWidth > 535 ? "desktop-view" : "mobile-view"
          } ${
            (!linkedBundleId || (selectCrousePopup && !passedCheck)) &&
            "slider-screen"
          } `}
        >
          {isDesktop && (
            <ColorContrastButton
              setIsColorContrast={setIsColorContrast}
              isColorContrast={isColorContrast}
            ></ColorContrastButton>
          )}

          <ColorContrast.Provider value={isColorContrast}>
            <MapEventsContext.Provider
              value={{
                onItemSelected: setSelectedItem,
                isDesktop,
                setPopup: (
                  args,
                  isMap = false,
                  isMoreInfo = false,
                  isFromMapView = false,
                  closePopup = undefined
                ) => {
                  setClosePopup(closePopup);
                  if (isMoreInfo) {
                    setCenter(args.location.coordinates);
                    setIsClickMoreInfo(true);
                  } else {
                    setIsClickMoreInfo(false);
                  }
                  setPopup(args);
                  setIsFromMap(isMap);
                  if (!args) {
                    setIsFromMap(false);
                  }
                  setIsFromMapView(isFromMapView);
                },
                setMapMode: (isFrom) => {
                  setMapMode(isFrom);
                },
              }}
            >
              <>
                {!TermsAgreed ? (
                  <TermsPopup
                    isColorContrast={isColorContrast}
                    setDeclineClicked={() => {
                      setTermsAgreed(true);
                    }}
                    setAcceptClicked={() => {
                      setTermsAgreed(true);
                      localStorage.setItem("terms-agreed", true);
                    }}
                  ></TermsPopup>
                ) : (
                  <>
                    {selectCrousePopup || (
                      <>
                        {" "}
                        {(isDesktop || !mapMode) && (
                          <Crouse
                            onTabChanged={(currentTab) =>
                              setSelectedTab(currentTab)
                            }
                            isColorContrast={isColorContrast}
                            closePopup={closePopup}
                            loading={loader}
                            setLoading={setLoader}
                            setCrouseData={setSelectedCrouse}
                            bundleData={allBundleData}
                            centerLocation={
                              crouseData?.startLocation?.coordinates ||
                              currentLocation
                            }
                            items={items}
                            isFromMap={isFromMap}
                            crouseData={crouseData}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            pdfLoading={pdfLoading}
                            downloadPdfs={downloadPdfs}
                            ref={backFocusRef}
                            center={center}
                            dockingPoint={dockingPoint}
                            isClickMoreInfo={isClickMoreInfo}
                            popUp={popUp}
                            bundleId={bundleId}
                          ></Crouse>
                        )}
                        {(selectedTab === "attraction" ||
                          selectedTab === "generalinfo") && (
                          <Map
                            pointCenter={crouseData?.startLocation?.coordinates}
                            center={center}
                            generalInfo={crouseData?.generalTips}
                            dockingPoint={dockingPoint}
                            selectedItem={selectedItem}
                            popUp={popUp}
                            items={crouseData?.types
                              ?.map((t) =>
                                t.items.map((i) => ({
                                  ...i,
                                  icon: t.typeTitle,
                                }))
                              )
                              ?.flat()}
                            isMoreInfo={isClickMoreInfo}
                          ></Map>
                        )}
                      </>
                    )}
                    {popUp && (
                      <ItemPopup
                        item={popUp}
                        isColorContrast={isColorContrast}
                        pointCenter={crouseData?.startLocation?.coordinates}
                        center={center}
                        isFromMap={isFromMap}
                        crouseData={crouseData}
                        bundleId={bundleId}
                        itemId={crouseData._id}
                        client={allBundleData.clientId}
                        selectedItem={selectedItem}
                        centerLocation={
                          crouseData?.startLocation?.coordinates ||
                          currentLocation
                        }
                        items={crouseData?.types
                          ?.map((t) =>
                            t.items.map((i) => ({ ...i, icon: t.typeTitle }))
                          )
                          ?.flat()}
                        isClickMoreInfo={isClickMoreInfo}
                        isFromMapView={isFromMapView}
                      ></ItemPopup>
                    )}
                  </>
                )}
                {selectCrousePopup && (
                  <SignInCheck
                    isColorContrast={isColorContrast}
                    loader={loader}
                    setPassedCheck={setPassedCheck}
                    allBundleData={allBundleData}
                    passedCheck={passedCheck}
                    message={message}
                    check={check}
                    setMessage={setMessage}
                    crouseData={crouseData}
                    closePopUp={() => setSelectCrousePopup(false)}
                    setBundle={setBundleId}
                    bundleData={pages}
                    setCrouseData={setSelectedCrouse}
                    pdfDown={pdfDown}
                    pdfLoading={pdfLoading}
                    downloadPdfs={downloadPdfs}
                  />
                )}
              </>
            </MapEventsContext.Provider>
          </ColorContrast.Provider>
        </div>
      )}
    </>
  );
}

export default MobileView;
///
