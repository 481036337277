/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
import locationGif from "../images/location.gif";

import "./popup.css";
import "./SignInBundle.css";
import "./mobile-view.css";
import "./desktop-view.css";
import "./utility.css";

function SignInBundleId(props) {
  const { setBundle, message, closePopUp } = props;
  const [idBundle, setIdBundle] = useState("");
  const [imageNumber, setimageNumber] = useState(1);
  const isMobile = true;
  const isCheckMobile = window.innerWidth < 535;

  const navigate = useNavigate();

  useEffect(() => {
    if (isCheckMobile) {
      const deviceHeight = window.innerHeight;
      const waveDiv = document.getElementsByClassName("wave");
      waveDiv[0].style.height = `calc(${deviceHeight}px - ${486}px)`;
    }
    if (isMobile) {
      const bodyEle = document.getElementsByTagName("body");
      bodyEle[0].style.position = "relative";
      bodyEle[0].style.touchAction = "none";
      return () => {
        bodyEle[0].style.overflow = "hidden";
      };
    }
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      var nextNum = imageNumber + 1;
      setimageNumber(nextNum % 2);
    }, 3000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [imageNumber]);

  const handleLetsStart = () => {
    setBundle("all");
    navigate("/all");
    closePopUp();
    if (!message) setIdBundle(" ");
    ReactGA.event({
      category: idBundle[0],
      action: "click",
      label: "EnterGuideCode",
    });
  };

  return (
    <div
      className={` ${
        !isCheckMobile ? "main-slider-bg-mobile" : "main-slider-bg"
      } position-relative`}
    >
      <div className="text-center logo-block">
        <img src="/images/guideful-logo.svg" alt="guideful-logo"></img>
      </div>

      <div>
        <div className="box-shedow-block login-page-card">
          <img
            src={locationGif}
            alt="slider-location"
            className="mx-auto location-image img-fluid d-block"
          ></img>

          <p className="mb-0 mt-0">
            Explore the map filled with our recommendations to make the most of
            your trip!
          </p>
          <button
            onClick={() => handleLetsStart()}
            className="lets-start btn lets-start-btn-mobile"
          >
            lets start
          </button>
        </div>
      </div>

      <div className="text-center">
        <img
          src="https://guideful.travel/wp-content/uploads/2023/01/Ship.gif"
          alt="guideful-logo"
          className="img-fluid ship"
          height={100}
          width={170}
        ></img>
      </div>
      {isCheckMobile && <div className="wave"></div>}
    </div>
  );
}

export default SignInBundleId;
//
