import { useNavigate } from "react-router-dom";

const TermsMenu = () => {
  const navigate = useNavigate();
  return (
    <div className="terms-menu">
      <div className=" terms-partner-title">
        Guideful: Attraction Discovery Done Right.
      </div>
      <div>
        {" "}
        <div
          className="item-more-info website-button"
          tabIndex={0}
          onClick={() => navigate("/")}
        >
          Back To The Site
        </div>
      </div>
    </div>
  );
};
export default TermsMenu;
