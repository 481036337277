import { useContext, useEffect, useState, useRef } from "react";
import { IconButton } from "./IconButton";
import { OpeningHours } from "./OpeningHours";
import Popup from "./popup";
import SharePopup from "./SharePopup";
import "./popup.css";
import { MapEventsContext, ColorContrast } from "../utils/contexts";
import "../ColorContrast/colorContrast.css";
import SvgIcon from "../Footer/SvgIcon";
import MobilePopup from "./MobilePopup";
import { getDistance } from "geolib";
import { isRestaurantOpen, isPlaceOpen } from "../utils/utils";
import config from "../configData.json";

function ItemPopup(props) {
  const {
    item,
    pointCenter,
    center,
    selectedItem,
    items,
    client,
    itemId,
    crouseData,
    isFromMap,
    centerLocation,
    isFromMapView,
  } = props;
  const [isShareOpen, setIsSharePopup] = useState(false);
  const [isFromMapMobile, setIsFromMapMobile] = useState(null);
  const eventsContext = useContext(MapEventsContext);
  const isColorContrast = useContext(ColorContrast);
  const focusRef = useRef(null);
  const childRef = useRef(null);
  const handlePress = (e) => {
    if (e.key === "Enter") {
      setIsSharePopup(true);
    }
  };
  useEffect(() => {
    if (!item.openingHours && item.redirectionUrl) {
      window.open(item.redirectionUrl);
      eventsContext.setPopup(null);
    }
  }, []);

  useEffect(() => {
    setIsFromMapMobile(isFromMap);
  }, [isFromMap]);

  function handleKeyPress(event) {
    if (event.key === "Tab" && !event.shiftKey) {
      childRef.current.childFunction();
    }
  }
  function focusOnFirstElement() {
    focusRef.current.focus();
  }

  const handleClickLocation = (location) => {
    window.open(
      `https://maps.google.com/?q=${location.coordinates[0]},${location.coordinates[1]}`,
      "_blank"
    );
  };

  const handleCheckOpen = (item) => {
    const resturantState = isRestaurantOpen(item);
    return resturantState
      ? resturantState.length
        ? resturantState
        : "Open"
      : "Closed";
  };

  const handleCheckOpen2 = (item) => {
    return isPlaceOpen(item) ? "Open" : "Closed";
  };

  const closeGeneralInfoPopup = () => {
    setIsFromMapMobile(false);
    eventsContext.setPopup(null);
  };

  const handleCloseSmallPopup = () => {
    setIsFromMapMobile(false);
    eventsContext.setPopup(null);
  };

  const handleClickMoreInfo = (item) => {
    setIsFromMapMobile(false);
    eventsContext.setPopup(item, false, true, true);
  };

  const shareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Guideful- guiding you through your travels",
          url: config.redirectUrl,
          icon: "https://beta-studio-prod-cdn-origin.s3.eu-south-1.amazonaws.com/carnival-public/test-folder/GuidefulLogo.svg",
        })
        .then(() => console.log("Link shared successfully"))
        .catch((error) => console.log("Error sharing link:", error));
    } else {
      console.log("Web Share API not supported in this browser");
    }
  };

  const showIcon = (title) => {
    switch (title) {
      case "Police Station":
        return "Police.svg";
      case "Tourist information office":
        return "Tourist_info.svg";
      case "Pharmacy":
        return "Pharmacy.svg";
      default:
        return "Tourist_info.svg";
    }
  };

  const isMobile = true;

  return (
    <div>
      {isMobile && !isFromMapMobile && !item?.isGeneralInfo ? (
        <div className="item-popup-container header-location attraction-popup general-info-popup time-hours">
          <div>
            <ColorContrast.Provider value={isColorContrast}>
              <MobilePopup
                focusOnFirstElement={focusOnFirstElement}
                pointCenter={pointCenter}
                center={center}
                selectedItem={selectedItem}
                items={items}
                itemData={item}
                ref={childRef}
                isFromMapView={isFromMapView}
              >
                <div className="popup-title color-contrast">
                  <div className="place-info">
                    <img
                      src={item?.imageUrl}
                      alt="place-img"
                      height={220}
                      width={330}
                      className="img-fluid more-info-img popup-img-fluid  popup-image-fluid"
                    ></img>

                    <div className="social-share">
                      <img
                        className="color-contrast bg-color"
                        alt={"share " + item.title}
                        src={`/icons/share-cunard.svg`}
                        onClick={shareLink}
                        aria-haspopup={"true"}
                        role={"button"}
                        tabIndex={0}
                      ></img>
                    </div>
                  </div>
                  <div className="info-name">{item?.title}.</div>
                  <div className=" crouse-item-type item-subtype">
                    {item.subType}
                  </div>
                  {item?.location?.accesible && (
                    <div className="crouse-item-accessible">
                      <div className="crouse-item-accessible-icon">
                        {SvgIcon("chairIcon")}
                      </div>
                      <div className="crouse-item-accessible-text">
                        wheelchair-accessible entrance
                      </div>
                    </div>
                  )}
                </div>
                <hr></hr>
                {item.openingHours && (
                  <OpeningHours openingHours={item.openingHours}></OpeningHours>
                )}

                <hr></hr>

                <div className="popup-item-iconst">
                  {item?.location && (
                    <div onClick={() => handleClickLocation(item.location)}>
                      {SvgIcon("direction")}
                      <p>Directions</p>
                    </div>
                  )}

                  <div
                    onClick={() =>
                      window.open(item.redirectionUrl || item.contact?.website)
                    }
                  >
                    {SvgIcon("website")}

                    <p>Website</p>
                  </div>
                  {item?.contact?.phone && (
                    <div
                      onClick={() =>
                        (window.location = "tel:" + item.contact.phone)
                      }
                    >
                      {SvgIcon("call")}
                      <p>Call</p>
                    </div>
                  )}
                </div>
                <hr></hr>
                {item.subType && (
                  <div className=" crouse-item-type item-subtype">
                    {item.location.address}
                  </div>
                )}
                <div className="crouse-item-buttons color-contrast">
                  {item.redirectionUrl && (
                    <div
                      className="item-more-info website-button color-contrast"
                      tabIndex={0}
                      onClick={() =>
                        window.open(
                          item.redirectionUrl || item.contact?.website
                        )
                      }
                    >
                      Contact Us{" "}
                    </div>
                  )}
                </div>
                {isShareOpen && (
                  <SharePopup
                    itemId={itemId}
                    client={client}
                    redirectUrl={config.redirectUrl}
                    imageUri={crouseData.socialImageUrl}
                    closePopup={() => setIsSharePopup(false)}
                    item={item}
                    onKeyPress
                  ></SharePopup>
                )}
              </MobilePopup>
            </ColorContrast.Provider>
          </div>
        </div>
      ) : isFromMapMobile && !item?.isGeneralInfo ? (
        <div className="box-center item-small-popup">
          <div className="card-block">
            <div className="d-flex">
              <img src={item?.imageUrl} alt="powered_by_google"></img>
              <div
                onClick={() => handleCloseSmallPopup()}
                className="close-position item-small-popup-close"
              >
                {SvgIcon("close-icon")}
              </div>
            </div>
            <div className="card-details">
              <p className="m-0 main-title">{item?.title}.</p>
              <p className="m-0 placename"> {item.subType}</p>
              {/* <p className="m-0 open-time">Open</p> */}
              <p className="m-0 open-time">
                {handleCheckOpen2(item?.openingHours)} |{" "}
                {(
                  getDistance(
                    crouseData?.dockingPoint?.coordinates,
                    item.location.coordinates
                  ) * 0.001
                ).toFixed(2)}{" "}
                km from conference
              </p>

              <a onClick={() => handleClickMoreInfo(item)} className="m-0">
                more info +
              </a>
            </div>
          </div>
        </div>
      ) : item?.isGeneralInfo ? (
        <div className="box-center small-card-icon info-box">
          <div className="card-block">
            <div>
              <img
                className="icon"
                src={`/images/${showIcon(item?.title)}`}
                alt="info-icons"
              ></img>
              <div
                className="close-position"
                onClick={() => closeGeneralInfoPopup()}
              >
                <img src="/images/close-icons.png" alt="close-icons"></img>
              </div>
            </div>
            <div>
              <p className="m-0">{item?.title}</p>

              <p className="m-0">
                <a
                  style={{ textDecoration: "none" }}
                  href={item?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item?.address}
                </a>
              </p>
              <p className="m-0">
                <a style={{ textDecoration: "none" }}>{item?.phone}</a>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="item-popup-container ">
          <div>
            <ColorContrast.Provider value={isColorContrast}>
              <Popup
                focusOnFirstElement={focusOnFirstElement}
                pointCenter={pointCenter}
                center={center}
                selectedItem={selectedItem}
                items={items}
                ref={childRef}
              >
                <div className="popup-title color-contrast">
                  <div className="place-info">
                    {/* <img
                      src="https://lh3.googleusercontent.com/places/AAcXr8o52jBUOraF3dDztoz40j_o-DHtj2QDe4gvSuhQ_h0_m09hQf4YU_z0ch9eME5VNID3yPJMFVB0oMMP3vMypMUIeYYn0FO63Rw=w900"
                      alt="place-img"
                      className="img-fluid"
                    ></img> */}

                    <div className="social-share">
                      <img
                        className="color-contrast bg-color"
                        alt={"share " + item.title}
                        src={`/icons/share-cunard.svg`}
                        onClick={() => setIsSharePopup(true)}
                        onKeyPress={handlePress}
                        aria-haspopup={"true"}
                        role={"button"}
                        tabIndex={0}
                      ></img>
                    </div>
                  </div>
                  <div className="info-name">{item.title}</div>
                </div>
                <hr></hr>
                {item.openingHours && (
                  // <button className="accessibility-button" ref={focusRef}>
                  <OpeningHours openingHours={item.openingHours}></OpeningHours>
                  // </button>
                )}

                <hr></hr>
                <div className="popup-item-iconst">
                  {item.location && (
                    <a
                      href={`https://maps.google.com/?q=${item.location.coordinates[0]},${item.location.coordinates[1]}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton
                        text="directions"
                        icon="directions"
                      ></IconButton>
                    </a>
                  )}
                  <IconButton
                    text="website"
                    icon="website"
                    click={() =>
                      window.open(item.redirectionUrl || item.contact?.website)
                    }
                  ></IconButton>
                  {item.contact?.phone && (
                    <button
                      className="accessibility-button"
                      onKeyUp={(e) => handleKeyPress(e)}
                    >
                      <IconButton
                        text="call"
                        icon="call"
                        click={() =>
                          (window.location = "tel:" + item.contact.phone)
                        }
                      ></IconButton>
                    </button>
                  )}
                </div>

                <hr></hr>
                {item.subType && (
                  <div className=" crouse-item-type item-subtype">
                    {item.location.address}
                  </div>
                )}
                <div className="crouse-item-buttons color-contrast">
                  {item.redirectionUrl && (
                    <div
                      className="item-more-info website-button color-contrast"
                      tabIndex={0}
                      onClick={() =>
                        window.open(
                          item.redirectionUrl || item.contact?.website
                        )
                      }
                    >
                      Contact Us{" "}
                    </div>
                  )}
                </div>
                {isShareOpen && (
                  <SharePopup
                    itemId={itemId}
                    client={client}
                    redirectUrl={config.redirectUrl}
                    imageUri={crouseData.socialImageUrl}
                    closePopup={() => setIsSharePopup(false)}
                    item={item}
                    onKeyPress
                  ></SharePopup>
                )}
              </Popup>
            </ColorContrast.Provider>
          </div>
        </div>
      )}
    </div>
  );
}

export default ItemPopup;
