import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ColorContrast, MapEventsContext } from "../utils/contexts";
import "./menu.css";
import GenericPopup from "../Popup/GenericPopup";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import AttractionPageMobile from "./AttractionPageMobile";
import GeneralInfoPageMobile from "./GeneralInfoPageMobile";
import Loader from "../Loader";
import PlaceDetailsMobile from "./PlaceDetailsMobile";

const isCheckMobile = window.innerWidth < 535;

function MobileMenu({
  bundleData,
  setCrouseData,
  handlePdf,
  closePopup,
  isLoading,
  setIsPdfReady,
  setNextDestinationIsClicked,
  nextDestinationClicked,
  crouseData,
  centerLocation,
  setSelectedtab,
  isFromMap,
  popUp,
  isClickMoreInfo,
  bundleId,
  selectedTab,
}) {
  const { linkedBundleId } = useParams();
  const eventsContext = useContext(MapEventsContext);
  const [locationOpen, setLocationsOpen] = useState("down");
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const [isAttractionOpen, setIsAttraction] = useState(false);
  const [isGeneralInfoOpen, setIsGeneralInfo] = useState(false);
  const [isTipsOpen, setIsTips] = useState(false);
  const isColorContrast = useContext(ColorContrast);
  const [isActiveFooter, setIsActiveFooter] = useState("placedetails");
  const [isActiveHeader, setIsActiveHeader] = useState("Coffee");
  const [showFooter, setShowFooter] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [isAttLoading, setIsAttLoading] = useState(false);
  const focusRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isClick, setIsClick] = useState(false);
  const [height, setHeight] = useState(null);
  const [position, setPositionDirection] = useState(0);
  const [mobileStyleObject, setMobileStyleObject] = useState({
    height: "auto",
    position: "relative",
    bottom: "0px",
    transition: "all 0.5s ease-in-out",
    marginTop: isCheckMobile ? "70vh" : "60vh",
    overflow: "hidden",
    scrollBehavior: "smooth",
  });

  const imageRef = useRef();

  let attractionData = { ...crouseData };

  let items = [];
  if (attractionData?.types) {
    if (!attractionData.bookings?.length) items = [...attractionData.types];
    else {
      const splitItemsAfter = Math.floor(
        attractionData?.types?.length / attractionData?.bookings?.length
      );
      let start = 0;
      let promotionIndex = 1;
      attractionData?.bookings?.map((item, index) => {
        item.bookingsItem = index + 1;
      });
      const bookingsCopy = [...attractionData.bookings];
      for (
        let i = splitItemsAfter;
        i <= attractionData.types.length;
        i += splitItemsAfter
      ) {
        items.push(...attractionData.types.slice(start, i));
        start = i;
        bookingsCopy.length &&
          items.push({
            ...bookingsCopy.shift(),
            promotionIndex: promotionIndex++,
          });
      }
    }
  }
  attractionData.types = items;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isHeaderOpen) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  }, [isHeaderOpen]);

  useEffect(() => {
    const bodyele = document.getElementsByTagName("body");
    if (window?.innerWidth > 535 || window?.innerWidth < 535) {
      if (isActiveFooter === "attraction") {
        if (bodyele) {
          bodyele[0].style.overflow = "auto";
        }
      } else {
        if (bodyele) {
          bodyele[0].style.overflow = "auto";
        }
      }
    } else if (
      isActiveFooter === "attraction" ||
      isActiveFooter === "generalinfo"
    ) {
      if (bodyele) {
        bodyele[0].style.overflow = "hidden";
      }
    } else {
      bodyele[0].style.overflow = "auto";
    }
  }, [isActiveFooter]);

  useEffect(() => {
    function scrollToPosition() {
      if (isActiveFooter === "attraction" || isActiveFooter === "generalinfo") {
        window.scrollTo(0, 300);
        setShowHeader(false);
        setShowMap(false);
        setShowFooter(true);
      }
    }
    scrollToPosition();
  }, [isActiveFooter]);

  useEffect(() => {
    function handleScroll(e) {
      if (isActiveFooter === "attraction" || isActiveFooter === "generalinfo") {
        if (window.scrollY > 600) {
          setShowHeader(true);
          setShowFooter(true);
          setShowMap(false);
        } else if (window.scrollY < 630 && window.scrollY > 100) {
          setShowHeader(false);
          setShowMap(false);
          setShowFooter(true);
        } else if (window.scrollY < 100 || window.scrollY <= 2) {
          !isClickMoreInfo && setShowHeader(false);
          !isClickMoreInfo && setShowFooter(false);
          !isClickMoreInfo && setShowMap(true);
        }
      }
    }

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isActiveFooter, isClickMoreInfo]);

  function handleKeyDown(event) {
    if (event.keyCode === 27) {
      setLocationsOpen(locationOpen === "down" ? "up" : "down");
    }
    if (event.key === "Esc") {
      event.preventDefault();
      setLocationsOpen(locationOpen === "down" ? "up" : "down");
    }
  }

  useEffect(() => {
    if (closePopup) {
      setShowFooter(false);
      setShowHeader(false);
      setShowMap(true);
    }
  }, [popUp]);

  const handleSetNoPopup = () => {
    setPositionDirection(90);
    setShowMap(true);
    window.scrollTo(0, 0);
    setShowFooter(false);
    setShowHeader(false);
  };

  const onClickFooter = (item) => {
    switch (item) {
      case "placedetails":
        setIsAttraction(false);
        setIsGeneralInfo(false);
        setIsTips(false);
        break;
      case "attraction":
        setIsAttraction(true);
        setIsGeneralInfo(false);
        setIsTips(false);
        setActiveIndex(0);
        setIsActiveHeader("Coffee");
        break;
      case "generalinfo":
        setIsAttraction(false);
        setIsGeneralInfo(true);
        setIsTips(false);

        break;
      case "tips":
        setIsAttraction(false);
        setIsGeneralInfo(false);
        setIsTips(true);
        break;
      default:
        break;
    }
    setIsActiveFooter(item);
    setSelectedtab(item);

    handleScrollTop();
    if (popUp) {
      eventsContext?.setPopup(null);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onClickHeader = (item, index) => {
    setIsActiveHeader(item);
    setActiveIndex(index);
    document.getElementById(`${item}`).scrollIntoView();
  };

  const handleOpenNavMobile = () => {
    setIsHeaderOpen(true);
  };

  const openMap = () => {
    handleSetNoPopup();
  };

  const handleOpenAttractionPopup = () => {
    if (!showFooter) {
      window.scrollTo(0, 300);
      setShowMap(false);
    } else {
      window.scrollTo(0, 700);
      setShowMap(false);
    }

    if (popUp) {
      eventsContext.setPopup(null);
    }
  };

  const showLocation = () => {
    let itemCount = 0;
    crouseData?.types?.map((item) => {
      itemCount = itemCount + item?.items?.length;
    });
    return (
      <span className="tagline-span">
        {itemCount} attractions await you in the {crouseData?.title}
      </span>
    );
  };

  useEffect(() => {
    if (popUp) {
      if (isClickMoreInfo) {
        window.scrollTo({ top: 0, behavior: "instant" });
        setShowFooter(true);
        setTimeout(() => {
          document.body.style.overflow = "hidden";
        }, 300);
      } else {
        setShowFooter(false);
      }
    }
  }, [isClickMoreInfo, isFromMap]);
  
  useEffect(() => {
    if (!isClickMoreInfo) {
      setTimeout(() => {
        document.body.style.overflow = "auto";
      }, 300);
    }
  }, [isClickMoreInfo]);

  function focusOnFirstElement() {
    focusRef.current.focus();
  }

  useEffect(() => {
    setTimeout(() => {
      onClickFooter("placedetails");
    }, 100);
  }, []);

  if (isAttLoading) {
    return <Loader />;
  }

  return (
    <>
      <>
        {isLoading && <Loader />}
        <>
          <div
            className="crouse-menu new-navbar"
            style={{ marginBottom: !isCheckMobile ? "unset" : "60px" }}
          >
            <div className="d-flex">
              <img src="/images/mobile-logo.svg" alt="mobile-logo"></img>
            </div>
            <div className="next-location-pdf align-items-center">
              <div onClick={() => handleOpenNavMobile()}>
                <p>Next Locations</p>
              </div>
              <div>
                <div onClick={handlePdf}>
                  <button class="lets-start btn header-pdf">
                    <img
                      src="/images/header-download.svg"
                      alt="header-download"
                      className="mr-8"
                    />
                    PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isActiveFooter === "attraction" &&
            !showMap &&
            !isHeaderOpen &&
            !isClickMoreInfo && (
              <div>
                <div className="fixed-maps">
                  <button
                    onClick={() => openMap()}
                    class="lets-start btn header-pdf"
                  >
                    <img
                      src="/images/btn-maps.svg"
                      alt="header-download"
                      className="mr-8"
                    ></img>
                    Map
                  </button>
                </div>
              </div>
            )}
        </>
      </>

      <>
        {isHeaderOpen && (
          <div className="location-popup bottom-all-area">
            <div className="item-popup-container header-location">
              <div>
                <ColorContrast.Provider value={isColorContrast}>
                  <GenericPopup
                    isFromMobile
                    dynemicHeight={height}
                    closePopup={() => {
                      setIsHeaderOpen(false);
                      setSelectedtab("placedetails");
                      setIsActiveFooter("placedetails");
                      onClickFooter("placedetails");
                    }}
                    showX={true}
                    focusOnFirstElement={focusOnFirstElement}
                  >
                    <>
                      <p className="select-a-location">Select a location</p>

                      <div className="locations-menu">
                        {bundleData?.map?.((i) => (
                          <div
                            role={"link"}
                            key={i._id}
                            className={`location-menu-item ${
                              crouseData?.title === i.title && "active-tab"
                            }`}
                            tabIndex={0}
                            onKeyDown={handleKeyDown}
                            onClick={() => locationMenuClick(i, true)}
                          >
                            {i.title}
                          </div>
                        ))}
                      </div>
                    </>
                  </GenericPopup>
                </ColorContrast.Provider>
              </div>
            </div>
          </div>
        )}
        {selectedTab === "placedetails" && (
          <PlaceDetailsMobile crouseData={crouseData} imageRef={imageRef} />
        )}
        {isAttractionOpen && (
          <div
            style={{
              ...mobileStyleObject,
            }}
            className={`location-popup-new header-location attraction-popup ${
              isClickMoreInfo && popUp && "mobile"
            }`}
          >
            <div
              class="modal fade"
              id="myModal"
              role="dialog"
              data-keyboard="false"
              data-backdrop="static"
            >
              <ColorContrast.Provider value={isColorContrast}>
                <>
                  <div
                    onClick={() => handleOpenAttractionPopup()}
                    className="attraction-div"
                  >
                    <span className="attraction-span" />
                  </div>
                  {!showFooter && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10px",
                      }}
                    >
                      {showLocation()}
                    </div>
                  )}
                </>

                <div className="attraction-container">
                  {showHeader &&
                    showFooter &&
                    !popUp &&
                    isActiveFooter === "attraction" && (
                      <Header
                        onClick={(title, index) => onClickHeader(title, index)}
                        isActiveHeader={isActiveHeader}
                        setIsActiveHeader={setIsActiveHeader}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        isLoading={isLoading}
                        isClick={isClick}
                      />
                    )}

                  <AttractionPageMobile
                    attractionData={attractionData}
                    centerLocation={centerLocation}
                  />
                </div>
              </ColorContrast.Provider>
            </div>
          </div>
        )}

        {isGeneralInfoOpen && (
          <div
            onTouchMove={(value) => {
              setPositionDirection(
                (value.touches[0].clientY / window.innerHeight) * 100
              );
            }}
            className={`location-popup-new header-location attraction-popup general-info-popup-mobile general-info-popup

            `}
            style={{ ...mobileStyleObject }}
          >
            <div
              class="modal fade"
              id="myModal"
              role="dialog"
              data-keyboard="false"
              data-backdrop="static"
            >
              <div>
                <ColorContrast.Provider value={isColorContrast}>
                  {
                    <>
                      <div
                        onClick={() => handleOpenAttractionPopup()}
                        onTouchStart={() => handleOpenAttractionPopup()}
                        className="attraction-div"
                      >
                        <span className="attraction-span" />
                      </div>
                      {!showFooter && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "10px",
                          }}
                        >
                          <span className="tagline-span">General Info</span>
                        </div>
                      )}
                    </>
                  }
                  <GeneralInfoPageMobile crouseData={crouseData} />
                </ColorContrast.Provider>
              </div>
            </div>
          </div>
        )}

        {isTipsOpen && (
          <div
            onTouchMove={(value) => {
              setPositionDirection(
                (value.touches[0].clientY / window.innerHeight) * 100
              );
            }}
            className={` header-location attraction-popup general-info-popup general-info-popup-tips ${
              showMap ? "hide-footer-header" : "show-footer-header"
            } ${window.innerWidth > 535 && "desktop-height"}
               ${
                 showMap && !isCheckMobile && "hide-footer-header-web"
               }  ${"location-popup-scroll-general-info location-popup-scroll-general-info-tips"}`}
          >
            <div
              class="modal fade"
              id="myModal"
              role="dialog"
              data-keyboard="false"
              data-backdrop="static"
            >
              <div>
                <ColorContrast.Provider value={isColorContrast}>
                  <>
                    <div className="general-info tips">
                      <div className="text-center">
                        <h1 className="text-center">Tips.</h1>
                      </div>

                      <div className="mb-25">
                        <p className="m-0 mb-10 title-color">Tipping</p>
                        <p className="m-0">
                          {crouseData?.generalTips?.tipping}
                        </p>
                      </div>

                      <hr class="hr"></hr>

                      <div className="mb-25">
                        <p className="m-0 mb-10 title-color">
                          Locals dislike it when you
                        </p>
                        <p className="m-0">
                          {crouseData?.generalTips?.toAvoid}
                        </p>
                      </div>

                      <div className="tips-circle">
                        <hr class="hr tips-circle"></hr>
                        <img
                          src="/images/tips-circle.svg"
                          alt="mobile-logo"
                        ></img>
                      </div>

                      <div className="mb-25">
                        <p className="m-0 mb-10 title-color">Security</p>
                        <p className="m-0">
                          {crouseData?.generalTips?.security}
                        </p>
                      </div>
                    </div>
                  </>
                </ColorContrast.Provider>
              </div>
            </div>
          </div>
        )}
        {showFooter ? (
          <Footer
            onClick={(svg) => onClickFooter(svg)}
            isActiveFooter={isActiveFooter}
          />
        ) : null}
      </>
    </>
  );

  function locationMenuClick(i, isMobile) {
    if (isMobile) {
      setIsHeaderOpen(false);
    }
    if (isMobile && isActiveFooter === "attraction") {
      setShowMap(false);
    }
    onClickFooter("attraction");
    setCrouseData(i._id);
    setLocationsOpen("down");
    setIsPdfReady(false);
    navigate(`/${linkedBundleId}/${i?.title}`);
    setNextDestinationIsClicked(!nextDestinationClicked);
  }
}

export default MobileMenu;
