import React from "react";
import SvgIcon from "./SvgIcon";

const footerData = [
  {
    svg: "placedetails",
    title: "Place Details",
  },
  {
    svg: "attraction",
    title: "Attraction",
  },
  {
    svg: "generalinfo",
    title: "General info",
  },
  {
    svg: "tips",
    title: "tips",
  },
];

const Footer = ({ onClick, isActiveFooter }) => {
  return (
    <div className="crouse-menu new-navbar footer-bar">
      {footerData?.map((item) => (
        <div
          onClick={() => onClick(item.svg)}
          className={`d-flex flex-column align-items-center ${
            isActiveFooter === item.svg ? "active" : ""
          }`}
        >
          {SvgIcon(item.svg)}
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};
export default Footer;
