export function onEnter(func) {
  return (e) => {
    e.code === "Enter" || (e.code === "Space" && func());
  };
}

function isNowBetweenHoursMiami(hours) {
  // convert the hours to 24-hour clock format
  let startHour = hours[0]?.includes('AM') ? parseInt(hours[0]) : parseInt(hours[0]) + 12;
  let endHour = hours[1]?.includes('AM') ? parseInt(hours[1]) : parseInt(hours[1]) + 12;

  // get the current time in Miami time zone
  let now = new Date();
  let options = {timeZone: 'Europe/Madrid'};
  let nowSpain = new Date(now.toLocaleString('en-US', options));

  // get the current hour and minute in Miami time
  let currentHour = nowSpain.getHours();
  let currentMinute = nowSpain.getMinutes();

  // check if the current time is between the two hours
  if (currentHour >= startHour && currentHour < endHour) {
    return true;
  } else if (currentHour === endHour && currentMinute === 0) {
    // the end hour is inclusive, so check if the current minute is 0
    return true;
  } else {
    return false;
  }
}

export const isPlaceOpen = (openingHours) => {
  const strHours = openingHours[getTodayDay()] || "";

  if (strHours.includes('Open 24 hours')) {
    return true
  }
  if (strHours.includes('Closed')) {
    return false
  }

  const hours = strHours.split(" - ", 2)
  return hours.length === 1 ? false : isNowBetweenHoursMiami(hours)
}

export function isRestaurantOpen(openingHours) {
  const strHours = openingHours[getTodayDay()] || "";
  const isOpened = strHours.trim() === "Open 24 hours";
  if (
    strHours === "open 24 hr" ||
    isOpened ||
    strHours === "open" ||
    strHours === "Pending Change"
  )
    return isOpened ? true : strHours;
  const arrTimes = strHours.split(",");
  return arrTimes
    .map((strHours) => {
      let arrHours = strHours.split("–");
      if (arrHours.length === 1) arrHours = strHours.split(" – ");

      const now = new Date();
      arrHours = arrHours.map((i) => {
        let d = new Date(now.toDateString() + " " + i.trim());
        if (parseInt(i.split(":")[0] || "8") < 5) {
          d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
        }
        return d;
      });
      return (
        now.getTime() > arrHours[0].getTime() &&
        now.getTime() < arrHours[1].getTime()
      );
    })
    .some((i) => i);
}

export function getTodayDay() {
  var d = new Date();
  var weekday = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  return weekday[d.getDay()];
}
