import React, { useEffect, useState } from "react";
import Loader from "../Loader";

const PlaceDetailsMobile = ({ crouseData, imageRef }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <div className="basic-info">
      {isLoading && <Loader />}
      <div id="course-title-id" className="course-title" ref={imageRef}>
        <div className="img-ref-wrapper img-ref-text">
          {/* <img className="logo" src={bundleData.clientId.clientLogoURL}></img> */}
          <img
            id="main-img1"
            className="main-image"
            src={crouseData.imageUrl}
            alt=""
          ></img>
          <p className="img-link-wrapper place-detail-photoby">
            photo by:
            <span> </span>
            <a
              className="img-ref-link"
              target="_blank"
              rel="noreferrer"
              href={crouseData.pageImageRef.url}
              aria-label={
                "Google map " +
                crouseData.pageImageRef.title +
                " ,opens in a new window"
              }
            >
              {crouseData.pageImageRef.title}
            </a>
          </p>
        </div>

        <h1 className="course-title">{crouseData.city}.</h1>
        <div className="crouse-sub-title">{crouseData.pageSubTitle}.</div>
      </div>
      <hr className="hr"></hr>
      <div className="crouse-intro">
        <div className="crouse-desc-title" role={"heading"} aria-level="2">
          Introduction.
        </div>
        <div className="crouse-desc-value">{crouseData.introduction}</div>
      </div>
      <div className="crouse-desc">
        <div
          className="crouse-desc-title about-this-guid"
          role={"heading"}
          aria-level="2"
        >
          About this guide.
        </div>
        <div className="crouse-desc-value about-this-guid-value">
          {crouseData.description}
        </div>
      </div>

      <div className="guideful-privacy-policy">
        <p className="main-title">{`${crouseData.title} by Guideful.`}</p>
        <p>
          Whilst every care has been taken to ensure the accuracy of this guide,
          some of the information included is subject to change. Opening times,
          admission details and prices were correct at time of publication,
          however they should only be used as an indication. If you find any
          information to be incorrect, or have any suggestions for what else we
          could include in this guide, then we would like to hear from you.
          Write to: guideful support, support@guideful.travel.
        </p>
        <p>
          By using this service you agree to our{" "}
          <a
            href="https://guideful.travel/privacy-policy/"
            target={"_blank"}
            rel="noreferrer"
          >
            {`Privacy Policy `}
          </a>{" "}
          &
          <a
            href={"https://guideful.travel/terms-of-use/"}
            target="_blank"
            rel="noreferrer"
          >
            {` Terms of Use`}
          </a>
        </p>

        <img src="/images/powered_by_google.svg" alt="powered_by_google"></img>
      </div>
    </div>
  );
};

export default PlaceDetailsMobile;
