const LocationIcon = ({ onClick, icon, item, selectedItem }) => {
  const isSelected = selectedItem?.title === item.title;
  return (
    <div>
      <img
        alt=""
        className="map-place-icon"
        src={`/icons/${icon}.svg`}
        width="36px"
        height="36px"
        style={{ opacity: isSelected ? 1 : 0.7 }}
        onClick={onClick}
      ></img>
    </div>
  );
};

export default LocationIcon;
