import React, { useContext, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { MapEventsContext } from "../utils/contexts";
import LocationIcon from "./LocationIcon";

const CenterPoint = ({ onClick }) => {
  return (
    <div>
      <img
        className="map-place-icon"
        src={`/icons/startLocation.svg`}
        width="36px"
        height="42px"
        onClick={onClick}
        alt=""
      ></img>
    </div>
  );
};

function Map({
  items,
  center,
  selectedItem,
  zoomIn,
  dockingPoint,
  generalInfo,
  isMoreInfo = false,
  popUp,
}) {
  const mapEvents = useContext(MapEventsContext);
  const [minZoomIn, setMinZoomIn] = useState(12);
  const [defaultZoom, setDefaultZoom] = useState(14);
  const [height, setHeight] = useState(90);

  const handleShowPopup = (name, address, phone, link, coordinates) => {
    const element = document.getElementsByClassName("location-popup-new");

    if (element) {
      element[0].scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    const item = {
      title: name,
      address: address,
      phone: phone,
      link,
      location: {
        coordinates,
      },
      isGeneralInfo: true,
    };
    mapEvents.onItemSelected(item);
    mapEvents.isDesktop || mapEvents.setPopup(item, true);
  };

  useEffect(() => {
    if (popUp) {
      mapEvents.onItemSelected(popUp);
      isMoreInfo && setHeight(40);
      !isMoreInfo && setHeight(90);
    } else {
      mapEvents.onItemSelected(popUp);
      setHeight(90);
    }
  }, [popUp, isMoreInfo]);
  const isMobile = window.innerWidth < 768;

  const styles = {
    hide: [
      {
        featureType: "transit.station",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road.local",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  useEffect(() => {
    if (popUp) {
      setTimeout(() => {
        setMinZoomIn(14);
        setDefaultZoom(16);
      }, 1000);
    } else {
      setTimeout(() => {
        setMinZoomIn(12);
        setDefaultZoom(14);
      }, 1000);
    }
  }, [popUp]);

  const defaultProps = {
    zoom: defaultZoom,
  };

  return (
    // Important! Always set the container height explicitly
    <div className="map-container">
      <GoogleMapReact
        key={`${defaultProps.zoom}-${minZoomIn}`}
        options={{
          minZoom: minZoomIn,
          styles: styles["hide"],
          zoomControl: isMobile ? false : true,
          clickableIcons: false,
        }}
        style={{
          position: "absolute",
          height: ` ${height}% `,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
        bootstrapURLKeys={{ key: "AIzaSyBFdyonn45zixfNRgSE0jlbBFjwWr4Zo8Q" }}
        center={center ? center : [{}, {}]}
        defaultZoom={zoomIn ? zoomIn : defaultProps.zoom}
        map
      >
        {dockingPoint && (
          <CenterPoint
            lat={dockingPoint[0]}
            lng={dockingPoint[1]}
            onClick={() => mapEvents.onItemSelected(null)}
          ></CenterPoint>
        )}
        {items?.map((i) => (
          <LocationIcon
            key={i.title}
            selectedItem={selectedItem}
            lat={i.location.coordinates[0]}
            lng={i.location.coordinates[1]}
            icon={i.icon}
            text={i.title}
            item={i}
            onClick={() => {
              mapEvents.onItemSelected(i);
              mapEvents.isDesktop || mapEvents.setPopup(i, true);
            }}
          />
        ))}
        {generalInfo?.policeStation?.coordinates && (
          <LocationIcon
            key="police-station"
            lat={generalInfo?.policeStation?.coordinates[0]}
            lng={generalInfo?.policeStation?.coordinates[1]}
            icon="Police"
            item={generalInfo?.policeStation}
            onClick={() =>
              handleShowPopup(
                "Police Station",
                generalInfo?.policeStation?.address,
                generalInfo?.policeStation?.phone,
                `https://maps.google.com/?q=${generalInfo?.policeStation?.coordinates[0]},${generalInfo?.policeStation?.coordinates[1]}`,
                generalInfo?.policeStation?.coordinates
              )
            }
          />
        )}
        {generalInfo?.touristInfoOffice?.coordinates && (
          <LocationIcon
            key="tourist-info"
            lat={generalInfo?.touristInfoOffice?.coordinates[0]}
            lng={generalInfo?.touristInfoOffice?.coordinates[1]}
            icon="Tourist_info"
            item={generalInfo?.touristInfoOffice}
            onClick={() =>
              handleShowPopup(
                "Tourist information office",
                generalInfo?.touristInfoOffice?.address,
                generalInfo?.touristInfoOffice?.phone,
                `https://maps.google.com/?q=${generalInfo?.touristInfoOffice?.coordinates[0]},${generalInfo?.touristInfoOffice?.coordinates[1]}`,
                generalInfo?.touristInfoOffice?.coordinates
              )
            }
          />
        )}
        {generalInfo?.pharmacy?.coordinates && (
          <LocationIcon
            key="pharmacy"
            lat={generalInfo?.pharmacy?.coordinates[0]}
            lng={generalInfo?.pharmacy?.coordinates[1]}
            icon="Pharmacy"
            item={generalInfo?.pharmacy}
            onClick={() =>
              handleShowPopup(
                "Pharmacy",
                generalInfo?.pharmacy?.address,
                generalInfo?.pharmacy?.phone,
                `https://maps.google.com/?q=${generalInfo?.pharmacy?.coordinates[0]},${generalInfo?.pharmacy?.coordinates[1]}`,
                generalInfo?.pharmacy?.coordinates
              )
            }
          />
        )}
      </GoogleMapReact>
    </div>
  );
}

export default Map;
