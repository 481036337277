import TermsMenu from "./components/terns-menu";
import "./terms.css";
import "../App.css";
const TermsPage = () => {
  return (
    <div className="terms-page">
      <TermsMenu></TermsMenu>
      <div className="terms-content ">
        <div className="terms-text">
          <p className="terms-title">Cunard Guides Website terms of use.</p>

          <p>
            <strong>1. Introduction.</strong>
          </p>

          <p>
            1.1. www.cunardguides.com ("the Site") is owned by Carnival plc
            trading as Cunard ("we", "us" or “our") and hosted and managed by
            Dice Marketing Ltd (“Dice”). Use of the Site by a User (“you”) is on
            the basis of these terms and conditions contained in this document
            (“Terms of Use”). If you do not agree to these Terms of Use, stop
            using the Site immediately. The Terms of Use is the legal agreement
            between you and us and can only be amended with our consent. They
            should be read in conjunction with the details provided on the Site
            about how the Site operates and the products and services which are
            available.
          </p>

          <p>
            1.2. We reserve the right to change these Terms of Use from time to
            time without prior notice to you by changing them on the Site. We
            will post any updates to the Terms of Use on the Site from time to
            time and you should review the Terms of Use periodically for
            changes. Your continued use of the Site will constitute acceptance
            of the amended or updated Terms of Use.
          </p>

          <p>
            2. <strong>Accuracy of information.</strong>
          </p>

          <p>
            2.1. We are committed to using all reasonable endeavours to ensure
            that information on the Site or any other information we provide to
            you is accurate. However we make no representation or warranty that
            such information is accurate and up to date or complete and we
            accept no liability for any loss or damage caused by inaccurate
            information to the fullest extent permitted by law.
          </p>

          <p>
            2.2. If you find any inaccurate information on the Site please let
            us know and we will correct it, where we agree, as soon as we can.
          </p>

          <p>
            3. <strong>Privacy.</strong>
          </p>

          <p>
            Please see our{" "}
            <a href="https://www.cunard.com/en-gb/advice-and-policies/privacy-notice">
              Privacy Notice
            </a>{" "}
            for details on how we may use your personal information.{" "}
          </p>

          <p>
            4. <strong>Your use of information on this site.</strong>
          </p>

          <p>
            4.1. All content on this Site including but not limited to text,
            software, photographs, and graphics are protected by copyright. We,
            Dice or our licensors, own copyright and/or database rights in the
            selection, coordination, arrangement, and enhancement of such
            content, as well as in the original content. You may not modify,
            publish, transmit, participate in the transfer or sale, create
            derivative works, or in any way exploit, any of the content, in
            whole or in part except as provided in these Terms of Use.
          </p>

          <p>
            4.2. You may download information from the Site for your own
            personal non-commercial use only. Except as otherwise expressly
            permitted under copyright law, no copying, redistribution,
            retransmission, publication, or commercial exploitation of
            downloaded material will be permitted without our express permission
            and that of the copyright owner. In the event of any permitted
            copying, redistribution, or publication of copyright material, no
            changes in or deletion of author attribution, trademark legend, or
            copyright notice shall be made. You acknowledge that you do not
            acquire any ownership rights by downloading copyright material.
          </p>

          <p>
            5. <strong>Trade marks.</strong>
          </p>

          <p>
            “Cunard” and other trade marks, logos or brand names on this Site
            are owned, or used under licence by Carnival plc or its group
            companies and may not be reproduced without our express permission
            unless otherwise permitted under these Terms of Use. Other
            organisations and company names referred to on this Site may be
            trade marks of their respective owners as indicated.
          </p>

          <p>
            6. <strong>Links to this and other websites.</strong>
          </p>

          <p>
            6.1. We provide hyperlinks to other sites which are operated by
            other organisations. We are not responsible for external websites
            that link to this Site or which are linked from it. The provision of
            these hyperlinks does not imply any endorsement of the material on
            such sites or any association with their operators.
          </p>

          <p>
            6.2. We reserve the right at our discretion to prohibit any link
            from another internet site to materials or information on this Site.
            Without prejudice to that right, any link to material or information
            on this Site for which you are responsible must be neither
            misleading nor deceptive and must fairly indicate cunardguides.com
            as the destination of the link.
          </p>

          <p>
            7. <strong>Availability of the Site.</strong>
          </p>

          <p>
            7.1. Whilst we are committed to providing you with an excellent
            service by making our Site available we cannot guarantee that our
            Site will operate continuously or without interruptions or be error
            free. You must not attempt to interfere with the proper working of
            our Site and, in particular, you must not attempt to circumvent
            security, tamper with, hack into, or otherwise disrupt any computer
            system, server, our Site, router or any other internet-connected
            device.
          </p>

          <p>
            7.2. You are responsible for ensuring that your computer system
            meets all relevant technical specifications necessary to use our
            Site and is compatible with our Site. You also understand that we
            cannot and do not guarantee or warrant that any material available
            for downloading from our Site will be free from infection, viruses,
            and/or other code that has contaminating or destructive properties.
            You are responsible for implementing sufficient procedures and virus
            checks (including anti-virus and other security checks) to satisfy
            your particular requirements for the accuracy of data input and
            output.
          </p>

          <p>
            8. <strong>Google Maps Functionality</strong>{" "}
          </p>

          <p>
            8.1. The Site includes Google Maps features and content and the use
            of Google Maps features and content is subject to the then-current
            versions of the: (1) Google Maps/Google Earth Additional Terms of
            Service at https://maps.google.com/help/terms_maps.html; and{" "}
            <a>
              (2) Google Privacy Policy at
              https://www.google.com/policies/privacy/.{" "}
            </a>
          </p>

          <p>
            9. <strong>Our Liability.</strong>
          </p>

          <p>
            9.1. Although we hope the Site will be of interest to users, we
            accept no liability and offer no warranties in relation to this Site
            and its content, to the fullest extent such liability can be
            excluded by law. In particular, we make no warranty or
            representation that (a) the Site will meet your requirements, (b)
            the Site will be uninterrupted, timely, secure, or error-free, and
            (c) any errors in the Site will be corrected.
          </p>

          <p>
            9.2. You expressly acknowledge and agree that your use of the Site
            is at your sole risk. The Site is provided on an "as is" and "as
            available" basis.
          </p>

          <p>
            9.3. Any material downloaded or otherwise obtained through the use
            of the Site is done at your own discretion and risk and you will be
            solely responsible for any damage to your computer system or loss of
            data that results from the downloading of any such material.
          </p>

          <p>
            9.4. In no event shall we be liable to you for any indirect or
            consequential loss, loss of profit, revenue or goodwill arising from
            your use of the Site or information on the Site. To the fullest
            extent permitted by applicable law, we expressly disclaim all
            warranties, conditions and other terms of any kind, whether express
            or implied, including, but not limited to any implied term of
            satisfactory quality, fitness for a particular purpose, and any term
            as to the provision of services to a standard of reasonable care and
            skill or as to non-infringement of any intellectual property right.
            Subject as provided in these Terms of Use, all terms implied by law
            are excluded.
          </p>

          <p>
            9.5. We accept liability for death or personal injury caused by our
            negligence and responsibility for fraudulent misrepresentation that
            cannot, under English law, be excluded. Any statutory rights you may
            have as a consumer remain unaffected.
          </p>

          <p>
            10. <strong>General</strong>.{" "}
          </p>

          <p>
            10.1. You agree to reimburse us and our subsidiaries, affiliates,
            officers, agents and employees against all liabilities, claims and
            expenses that may arise from any breach of the Terms of Use by you
            or from any content you submit, post to or transmit through the
            Site, your use of the Site or any parts of it, your connection to
            the Site, or your violation of any rights of another.
          </p>

          <p>
            10.2. We reserve the right to assign the Terms of Use, and to assign
            or subcontract any or all of our rights and obligations under the
            Terms of Use. You may not without our prior written consent assign
            or dispose of the Terms of Use.
          </p>

          <p>
            10.3. The Terms of Use includes our Privacy Notice. Together they
            contain the whole of the agreement between us and you concerning the
            use of the Site and they replace all earlier agreements and
            understandings with you, except for any fraud or fraudulent
            representation by either of us. A person who is not a party to the
            Terms of Use has no right under the Contracts (Rights of Third
            Parties) Act 1999 to rely upon or enforce any term of the Terms of
            Use.
          </p>

          <p>
            10.4. In the event that any term of the Terms of Use is held to be
            invalid or unenforceable by judicial decree or decision, the
            remainder of the Terms of Use shall remain valid and enforceable.
          </p>

          <p>
            10.5. The Terms of Use shall be governed by and construed in
            accordance with the laws of England and Wales and any dispute
            between us regarding the Site will only be dealt with by the English
            courts.
          </p>

          <p>
            10.6. Carnival plc is a company registered in England and Wales. Our
            company number is 4039524 and our registered office address is
            Carnival House, 100 Harbour Parade, Southampton SO15 1ST. Our ABTA
            number is V8764 and our ATOL number is 6294. Our VAT registration
            number is GB 761 4300 58.
          </p>

          <p>
            10.7. Any formal legal notices should be sent to us at the address
            at the end of these Terms of Use by email confirmed by post.
          </p>

          <p>
            10.8. Failure by us to enforce a right under these Terms of Use does
            not result in waiver of such right.
          </p>

          <p>
            11. <strong>How to contact us.</strong>
          </p>

          <p>
            You are welcome to contact us in any of the following ways for
            general enquiries:
          </p>

          <p>
            ● by email at{" "}
            <a href="mailto:UK.Reservations@cunard.co.uk">
              UK.Reservations@cunard.co.uk
            </a>
            ;
          </p>

          <p>
            ● by telephone on 0344 338 8648 (local call charges apply); and/or
          </p>

          <p>
            ● by writing to us at Port Guide Editors, Cunard, Carnival House,
            100 Harbour Parade, Southampton SO15 1ST.
          </p>

          <p>
            If you experience problems with our Site or would like to comment on
            it, please email{" "}
            <a href="mailto:UK.Reservations@cunard.co.uk">
              UK.Reservations@cunard.co.uk
            </a>
          </p>

          <p>
            <strong>12. Updates.</strong>
          </p>

          <p>These Terms of Use were last updated on 7 March 2022.</p>
        </div>
      </div>
    </div>
  );
};
export default TermsPage;
