export const handlePdfDownload = async (crouseData) => {
  return new Promise((resolve, reject) => {
    if (crouseData?.pageGuideURL) {
      fetch(crouseData.pageGuideURL)
        .then(async (response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            resolve(fileURL);
          });
        })
        .catch(() =>
          alert(`${crouseData.title}: are missing an offline guide`)
        );
    } else {
      alert(`${crouseData.title}: are missing an offline guide`);
    }
  });
};
