/* eslint-disable react/jsx-no-target-blank */
import "./SignInBundle.css";
import "./popup.css";
import { useNavigate } from "react-router-dom";
import { onEnter } from "../utils/utils";

function SignInPageList(props) {
  const {
    setCrouseData,
    bundleData,
    closePopUp,
    allBundleData,
    pdfDown,
    pdfLoading,
    downloadPdfs,
  } = props;
  console.log(pdfLoading);
  const navigate = useNavigate();
  const handleSelect = (data) => {
    console.log({ data });
    setCrouseData(data?._id);
    if (allBundleData?.bundleUUID && data?.title)
      navigate(`/${allBundleData?.bundleUUID}/${data?.title}`);
    closePopUp();
  };

  const handleClickBtn = (redirect) => {
    window.location.href = redirect;
  };

  const handleBackClick = () => {
    window.location.href = "/";
  };

  return (
    <>
      <div className="main-slider-bg all-location position-relative">
        <div className="text-center logo-block">
          <img src="/images/guideful-logo.svg" alt="guideful-logo"></img>
        </div>

        <div className="box-shedow-block location-list">
          <div className="download-cruise">
            <div className="" onClick={() => handleBackClick()}>
              <img src="/images/arrow-left.svg" alt="arrow-left"></img>
            </div>
            <div
              onClick={() => {
                pdfDown || downloadPdfs();
              }}
            >
              <p className="d-flex">
                <img
                  src="/images/download-icon.svg"
                  alt="download-icon"
                  className="mr-4"
                ></img>
                Download Cruise PDFs
              </p>
            </div>
          </div>

          <div className="singIn-page-list">
            {bundleData?.map?.((i, index) => (
              <button
                key={index}
                role={"link"}
                className="singIn-page-item  website-button"
                onKeyPress={onEnter(() => handleSelect(i))}
                onClick={() => handleSelect(i)}
              >
                {i.title}
              </button>
            ))}
          </div>

          <div className="not-book-yet text-center">
            <p>Not booked yet?</p>
            <button
              class="lets-start btn"
              // onClick={() => handleClickBtn()}
            >
              Click here
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignInPageList;
