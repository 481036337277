import React, { useContext } from "react";
import { MapEventsContext } from "../utils/contexts";

const GeneralInfoPageMobile = ({ crouseData }) => {
  const { generalTips } = crouseData || {};
  const {
    currency,
    emergencyServices,
    policeStation,
    touristInfoOffice,
    pharmacy,
    taxi,
  } = generalTips || {};

  const eventsContext = useContext(MapEventsContext);

  const handleShowPopup = (name, address, phone, link, coordinates) => {
    const element = document.getElementsByClassName("location-popup-new");
    if (element) {
      element[0].scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    const item = {
      title: name,
      address: address,
      phone: phone,
      link,
      location: {
        coordinates,
      },
      isGeneralInfo: true,
    };
    eventsContext.onItemSelected(item);
    eventsContext.isDesktop || eventsContext.setPopup(item, true);
  };

  const handleOpenTaxiLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="general-info">
        <h1 className="text-center">General info.</h1>

        <div className="mb-25">
          <p className="m-0 title-color">Currency</p>
          <p className="m-0">{currency}</p>
        </div>

        <div className="mb-25">
          <p className="m-0 title-color">Emergency Services</p>
          <p className="m-0">{emergencyServices?.policePhone}</p>
        </div>

        <div className="mb-25">
          <p className="m-0 title-color">Police Station</p>
          <p
            className="m-0"
            onClick={() =>
              handleShowPopup(
                "Police Station",
                policeStation?.address,
                policeStation?.phone,
                `https://maps.google.com/?q=${policeStation?.coordinates[0]},${policeStation?.coordinates[1]}`,
                policeStation?.coordinates
              )
            }
          >
            <a>{policeStation?.address}</a>
            <br />
            <a>{policeStation?.phone}</a>
          </p>
        </div>

        <div className="mb-25">
          <p className="m-0 title-color">Tourist information office</p>
          <p
            className="m-0"
            onClick={() =>
              handleShowPopup(
                "Tourist information office",
                touristInfoOffice?.address,
                touristInfoOffice?.phone,
                `https://maps.google.com/?q=${touristInfoOffice?.coordinates[0]},${touristInfoOffice?.coordinates[1]}`,
                touristInfoOffice?.coordinates
              )
            }
          >
            <a>{touristInfoOffice?.address}</a>
            <br />
            <a>{touristInfoOffice?.phone}</a>
          </p>
        </div>

        <div className="mb-25">
          <p className="m-0 title-color">Pharmacy</p>
          <p
            className="m-0"
            onClick={() =>
              handleShowPopup(
                "Pharmacy",
                `${pharmacy?.address} - ${pharmacy?.name}`,
                pharmacy?.phone,
                `https://maps.google.com/?q=${pharmacy?.coordinates[0]},${pharmacy?.coordinates[1]}`,
                pharmacy?.coordinates
              )
            }
          >
            <a>
              {pharmacy?.address} - {pharmacy?.name}
            </a>
            <br />
            <a>{pharmacy?.phone}</a>
          </p>
        </div>

        <div className="mb-25">
          <p className="m-0 title-color">Taxi</p>
          <p
            className="m-0"
            onClick={() =>
              handleOpenTaxiLink(
                `https://maps.google.com/?q=${taxi?.coordinates[0]},${taxi?.coordinates[1]}`
              )
            }
          >
            <a>{taxi?.name}</a>
            <br />
            <a>{taxi?.phone}</a>
          </p>
        </div>
      </div>
    </>
  );
};
export default GeneralInfoPageMobile;
