import React, { useRef } from "react";
import GenericPopup from "./GenericPopup";
import "./popup.css";
import config from "../configData.json";

function SharePopup({ closePopup, item, client, imageUri, redirectUrl }) {
  const childRef = useRef(null);
  const FirstElement = useRef(null);
  const clientName = encodeURI(
    client.clientName.replace(" ", "").replace("&O", "O ").replace("&", "")
  );
  const facebookUrl = `${
    config.public_social_api
  }/fb?title=${clientName}&client_domain=${encodeURIComponent(
    "https://www." + redirectUrl
  )}&client_social_img=${encodeURIComponent(imageUri)}`; /// /item/${bundleId}/${itemId}`

  const shareText = ` Planning my time ashore with my fab digital port guide from ${clientName} that’s powered by local recommendations. `;
  const shareTextNoImage =
    shareText + encodeURIComponent("https://www." + redirectUrl);
  console.log(facebookUrl);

  function handleKeyPress(event) {
    if (event.key === "Tab" && !event.shiftKey) {
      childRef.current.childFunction();
    }
  }

  function focusOnFirstElement() {
    FirstElement.current.focus();
  }

  return (
    <div className="social-icon">
      <GenericPopup
        closePopup={closePopup}
        showX={true}
        focusOnFirstElement={focusOnFirstElement}
        ref={childRef}
      >
        <div className="title-row">Share {item.title}</div>
        <div className="share-row">
          <button
            className="accessibility-button"
            role={"link"}
            onClick={() => window.open("sms:?body=" + shareTextNoImage)}
          >
            <img
              alt="text, opens in a new window"
              src="/icons/texting.svg"
            ></img>
          </button>
          <button
            className="accessibility-button"
            role={"link"}
            ref={FirstElement}
            onClick={() =>
              window.open("https://wa.me?text=" + shareTextNoImage)
            }
          >
            <img
              alt="whatsaap , opens in a new window"
              src="/icons/whatsapp.png"
            ></img>
          </button>
          <button
            className="accessibility-button"
            role={"link"}
            onClick={() =>
              window.open(
                "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Planning+my+time+ashore&body=" +
                  shareTextNoImage
              )
            }
          >
            <img
              alt="email , opens in a new window"
              src="/icons/gmail.svg"
            ></img>
          </button>
          {/* <img src="/icons/instagram.svg"></img> */}
          <button
            className="accessibility-button"
            role={"link"}
            onClick={() => {
              const url =
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                  facebookUrl
                )}&quote=` + encodeURI(facebookUrl);
              console.log(url);
              // eslint-disable-next-line
              FB.ui(
                {
                  method: "share",
                  href: facebookUrl,
                  quote: shareText,
                },
                function (response) {}
              );
              // window.open(url)
            }}
          >
            <img
              alt="facebook , opens in a new window"
              src="/icons/facebook.svg"
            ></img>
          </button>
          <button
            className="accessibility-button"
            role={"link"}
            onKeyUp={(e) => handleKeyPress(e)}
            onClick={() =>
              window.open(
                "https://twitter.com/intent/tweet?url=" +
                  encodeURIComponent(facebookUrl) +
                  "&text=" +
                  shareText
              )
            }
          >
            <img
              alt="tweeter, opens in a new window"
              src="/icons/twitter.svg"
            ></img>
          </button>
        </div>
      </GenericPopup>
    </div>
  );
}

export default SharePopup;
