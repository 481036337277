import { getDistance } from "geolib";
import React, { useContext } from "react";
import SvgIcon from "../Footer/SvgIcon";
import { MapEventsContext } from "../utils/contexts";
import { isPlaceOpen } from "../utils/utils";

const AttractionPageMobile = ({ attractionData, centerLocation }) => {
  const eventsContext = useContext(MapEventsContext);

  const handleBookClick = (redirect) => {
    window.location.href = redirect;
  };

  const handleCheckOpen2 = (item) => {
    return isPlaceOpen(item) ? "Open" : "Closed";
  };

  const handleSetPopup = (crouseItem) => {
    eventsContext.setPopup(crouseItem, false, true);
  };

  const handleClickImage = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      {attractionData?.types?.map((crouse, index) => (
        <>
          {crouse?.items?.length > 0 ? (
            <div
              className="attraction-content scroll-margin"
              id={crouse?.typeTitle}
            >
              <div className="main-title">
                <span>{SvgIcon(crouse?.typeTitle)}</span>
                {crouse?.typeTitle}.
              </div>
              <p className="m-0">{crouse.typeDescription}</p>

              <hr class="hr"></hr>
              {crouse?.items?.map((crouseItem) => (
                <div className="item-box">
                  <div
                    className="attraction-img"
                    onClick={() =>
                      handleClickImage(crouseItem?.itemImageRef?.url)
                    }
                  >
                    <img
                      src={crouseItem?.imageUrl}
                      alt="mobile-logo"
                      className="img-fluid "
                    ></img>
                    {crouseItem?.itemImageRef?.title && (
                      <p className="attraction-title">
                        Photo By: {crouseItem?.itemImageRef?.title}
                      </p>
                    )}
                  </div>

                  <p className="item-title m-0">{crouseItem?.title}</p>
                  <p className="m-0 item-name">{crouseItem?.subType}</p>
                  <p className="m-0 item-name mb-10">
                    {handleCheckOpen2(crouseItem?.openingHours)} |{" "}
                    {(
                      getDistance(
                        attractionData?.dockingPoint?.coordinates,
                        crouseItem.location.coordinates
                      ) * 0.001
                    ).toFixed(2)}{" "}
                    km from conference
                  </p>

                  <p className="m-0 mb-16">{crouseItem?.description}</p>

                  <button
                    onClick={() => handleSetPopup(crouseItem)}
                    class="lets-start btn header-pdf more-info"
                  >
                    MORE INFO
                  </button>
                </div>
              ))}
            </div>
          ) : crouse?.bookingsItem === 1 ? (
            <div className="revenue-oriented">
              <p className="revenue-title m-0">{crouse?.title}</p>
              <p className="revenue-desc m-0">{crouse?.description}</p>
              <p className="revenue-desc m-0">
                Contact us today and we’ll get you to your destination, faster.
              </p>

              <button
                onClick={() => handleBookClick(crouse?.redirectionUrl)}
                class="lets-start btn book-pdf"
              >
                Contact Us
              </button>
            </div>
          ) : crouse?.bookingsItem === 2 ? (
            <div className="revenue-oriented measurement-oriented">
              <p className="revenue-title m-0">{crouse?.title}</p>
              <p className="revenue-desc m-0">{crouse?.description}</p>
              <p className="revenue-desc m-0">
                Contact us and let us create the best solution for your brand.
              </p>

              <button
                class="lets-start btn book-pdf"
                onClick={() => handleBookClick(crouse?.redirectionUrl)}
              >
                Contact Us
              </button>
            </div>
          ) : crouse?.bookingsItem === 3 ? (
            <div className="revenue-oriented experience-oriented">
              <p className="revenue-title m-0">{crouse?.title}</p>
              <p className="revenue-desc m-0">{crouse?.description}</p>
              <p className="revenue-desc m-0">Contact us today!</p>

              <button
                class="lets-start btn book-pdf"
                onClick={() => handleBookClick(crouse?.redirectionUrl)}
              >
                Contact Us
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      ))}
    </>
  );
};
export default AttractionPageMobile;
