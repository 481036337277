import { useState, useEffect } from "react";
import Menu from "./menu";
import "../ColorContrast/colorContrast.css";
import { handlePdfDownload } from "./API";
import Map from "../map/Map";
import SkeletonLoader from "./SkeletonLoader";
import MobileMenu from "./MobileMenu";
const isCheckMobile = window.innerWidth < 535;
const Crouse = ({
  centerLocation,
  closePopup,
  crouseData,
  selectedItem,
  setCrouseData,
  bundleData,
  pdfLoading,
  downloadPdfs,
  center,
  dockingPoint,
  isFromMap,
  popUp,
  isClickMoreInfo,
  bundleId,
  onTabChanged,
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [isPdfReady, setIsPdfReady] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [nextDestinationClicked, setNextDestinationIsClicked] = useState(false);
  const [selectedTab, setSelectedTab] = useState("placedetails");

  useEffect(() => {
    if (bundleId === "all") {
      setSelectedTab("");
    }
  }, [bundleId]);

  const handlePdf = (e) => {
    setLoadingPdf(true);
    setIsPdfReady(true);
    if (crouseData?.pageGuideURL) {
      handlePdfDownload(crouseData).then((resp) => {
        if (resp) {
          let alink = document.createElement("a");
          alink.href = resp;
          alink.download = `${crouseData.title}.pdf`;
          alink.click();
          setLoadingPdf(false);
          setIsPdfReady(false);
        }
      });
    } else {
      alert(`${crouseData?.title}: are missing an offline guide`);
      setLoadingPdf(false);
      setIsPdfReady(false);
    }
  };

  useEffect(() => {
    const element = document.getElementById("crouse-details1");
    if (element) {
      element.scrollTo(0, 0);
    }
  }, [nextDestinationClicked]);

  useEffect(() => {
    onTabChanged(selectedTab);
  }, [selectedTab]);

  if (!crouseData) {
    return "";
  }

  return (
    <div
      id="crouse-details1"
      className={"crouse-details crouse-bg-color"}
      onScroll={(e, i) => setScrollTop(e.target.scrollTop)}
    >
      {crouseData ? (
        <>
          <>
            {isCheckMobile ? (
              <MobileMenu
                handlePdf={handlePdf}
                isLoading={loadingPdf}
                closePopup={closePopup}
                setCrouseData={setCrouseData}
                bundleData={bundleData.pages}
                clientId={bundleData.clientId}
                isAllLoading={pdfLoading}
                downloadPdfs={downloadPdfs}
                setIsPdfReady={setIsPdfReady}
                setNextDestinationIsClicked={setNextDestinationIsClicked}
                nextDestinationClicked={nextDestinationClicked}
                crouseData={crouseData}
                centerLocation={centerLocation}
                selectedTab={selectedTab}
                setSelectedtab={setSelectedTab}
                isFromMap={isFromMap}
                popUp={popUp}
                isClickMoreInfo={isClickMoreInfo}
                bundleId={bundleId}
              ></MobileMenu>
            ) : (
              <Menu
                handlePdf={handlePdf}
                isLoading={loadingPdf}
                closePopup={closePopup}
                setCrouseData={setCrouseData}
                bundleData={bundleData.pages}
                clientId={bundleData.clientId}
                isAllLoading={pdfLoading}
                downloadPdfs={downloadPdfs}
                setIsPdfReady={setIsPdfReady}
                setNextDestinationIsClicked={setNextDestinationIsClicked}
                nextDestinationClicked={nextDestinationClicked}
                crouseData={crouseData}
                centerLocation={centerLocation}
                selectedTab={selectedTab}
                setSelectedtab={setSelectedTab}
                isFromMap={isFromMap}
                popUp={popUp}
                isClickMoreInfo={isClickMoreInfo}
                bundleId={bundleId}
              ></Menu>
            )}
          </>
          {selectedTab !== "placedetails" && selectedTab !== "tips" ? (
            <Map
              pointCenter={crouseData?.startLocation?.coordinates}
              center={center}
              generalInfo={crouseData?.generalTips}
              dockingPoint={dockingPoint}
              selectedItem={selectedItem}
              items={crouseData?.types
                ?.map((t) =>
                  t.items.map((i) => ({
                    ...i,
                    icon: t.typeTitle,
                  }))
                )
                ?.flat()}
            ></Map>
          ) : null}
        </>
      ) : (
        <SkeletonLoader />
      )}
    </div>
  );
};

export default Crouse;
