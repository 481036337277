import consts from "../consts"

export const pdf = async (options) => {
  const { url, guideDomain, cities } = options
  try {
    const res = await fetch(`${consts.SERVICE_URI}/${url}?guideDomain=${guideDomain}&cities=${cities}`, {
      responseType: 'blob',
    })

    res.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'cruise.pdf'
      a.click()
    })
  } catch (e) {
    return e
  }
}
