import { useEffect, useState } from "react";

export const useLoadImages = async () => {
  const [loading, setloading] = useState(true);
  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      setloading(false);
    };
    img.onerror = function () {
      setloading(false);
    };
    const partner = window.location.hostname
      .replace("www.", "")
      .replace(".com", "");
    setloading(false);
    img.src = `/images/bg-${partner}-${"mobile"}.jpg`;
  }, []);

  return { loading };
};
