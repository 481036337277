import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ApplicationRoutes from "./ApplicationRoutes";
import { Helmet } from "react-helmet";

const favicon = "/guideful.ico";

const googleTagManager = "TPJRFN4";

ReactDOM.render(
  <React.StrictMode>
    <ApplicationRoutes />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export function getHelmetData() {
  return (
    <>
      <Helmet
        link={[
          {
            rel: "icon",
            href: favicon,
          },
        ]}
      >
        <title>Guideful: Attraction Discovery Done Right.</title>
        <meta name="robots" content="noindex, nofollow" />
        <link rel="icon" href={favicon} />

        {/* <!-- Google Tag Manager of guideful.travel --> */}
        <script type="text/javascript">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-P4B96RK');`}
        </script>
        {/* <!-- End Google Tag Manager --> */}
      </Helmet>

      {/* <!-- Google Tag Manager (noscript) of guideful.travel --> */}
      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P4B96RK"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
}
