import React, { useEffect } from "react";
import Desktop from "./Desktop";
import MobileView from "./MobileView";

export default function App() {
  const isMobile = window.innerWidth < 535;
  useEffect(() => {
    const bodyEle = document.getElementsByTagName("body");
    if (bodyEle && bodyEle[0] && !isMobile) {
      bodyEle[0].classList.add("desktop-bg");
    } else if (
      bodyEle &&
      bodyEle[0] &&
      bodyEle[0].classList.contains("desktop-bg") &&
      isMobile
    ) {
      bodyEle[0].classList.remove("desktop-bg");
    }
  }, [isMobile]);

  return <>{isMobile ? <MobileView /> : <Desktop />}</>;
}
