/* eslint-disable react/jsx-no-target-blank */
import { useState } from "react";

import "./popup.css";
import "./termpopup.css";
import GenericPopup from "./GenericPopup";

function TermsPopup({ setAcceptClicked, setDeclineClicked }) {
  const [decline, setDecline] = useState(false);

  return (
    <div className="location-popup bottom-all-area">
      <div className="item-popup-container header-location header-term-popup">
        <div>
          <GenericPopup isFromTerm closePopup={setDeclineClicked} showX={true}>
            {decline ? (
              <>
                <img src="/icons/alert.svg" className="alert-img" alt="!"></img>
                <div className="error-popup-title">Error Message</div>
                <div className="error-popup-text">
                  sorry we can’t provide service without using cookies
                </div>
                <div className="error-popup-bottom">500 Error</div>

                <div className="crouse-item-buttons">
                  <button
                    className="item-more-info  website-button"
                    tabIndex={0}
                    onClick={() => setDecline(false)}
                  >
                    BACK TO accept
                  </button>
                </div>
                <div className="crouse-item-buttons">
                  <button
                    className="item-more-info  website-button"
                    tabIndex={0}
                    onClick={() => window.close()}
                  >
                    CLOSE THIS SITE
                  </button>
                </div>
              </>
            ) : (
              <div className="term-popup-main">
                <div className="term-popup-content">
                  We use Google Maps to provide core functionality on this site.
                  You may prevent this by changing your browser settings, but
                  this may affect how the website functions.We would like to use
                  Google Analytics to help us improve our website by collecting
                  and reporting information on how you use it. This information
                  does not directly identify anyone.
                </div>
                <div className="text-center">
                  <div className="decline-btn" onClick={setDeclineClicked}>
                    Decline
                  </div>
                  <div className="accept-btn" onClick={setAcceptClicked}>
                    Accept
                  </div>
                </div>

                <div className="condition-link">
                  By using this service you agree to our{" "}
                  <a
                    className="m-r-2"
                    href={"https://guideful.travel/privacy-policy/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Privacy Policy
                  </a>
                  &
                  <a
                    className="m-l-2"
                    href="https://guideful.travel/terms-of-use/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Terms of Use
                  </a>
                  .
                </div>
              </div>
            )}
          </GenericPopup>
        </div>
      </div>
    </div>
  );
}

export default TermsPopup;
//
