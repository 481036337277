import React, { useContext } from "react";
import "./popup.css";
import { forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import { ColorContrast } from "../utils/contexts";
import SvgIcon from "../Footer/SvgIcon";

const GenericPopup = forwardRef(
  (
    {
      closePopup,
      isFromMobile,
      classNames,
      childClassName,
      children,
      showX = true,
      focusOnFirstElement,
      isFromTerm,
      dynemicHeight,
    },
    ref
  ) => {
    const focusOCloseButton = useRef(null);
    const isColorContrast = useContext(ColorContrast);

    useEffect(() => {
      focusOCloseButton.current.focus();
    }, []);

    const handlePress = (e) => {
      if (e.key === "Tab" && e.shiftKey) {
        focusOnFirstElement();
      }
      if (e.key == "Enter") {
        closePopup();
      }
    };

    useImperativeHandle(ref, () => ({
      childFunction() {
        focusOCloseButton.current.focus();
      },
    }));

    return (
      <div className={"popup-window-cover " + classNames}>
        <div
          className={`popup-container ${
            isFromMobile ? "menu-popup-container" : ""
          }`}
        >
          <div
            className={`${isColorContrast ? "popup color-contrast" : "popup"} ${
              isFromMobile ? "location-menu-poup" : ""
            }`}
            style={{
              height: isFromMobile ? `calc(100vh - ${dynemicHeight}px)` : "",
            }}
          >
            {showX && (
              <div
                className="close-button close-icon"
                onClick={closePopup}
                onKeyDown={handlePress}
                ref={focusOCloseButton}
                tabIndex={0}
                role={"button"}
              >
                <span className="hiddenText">close popup</span>
                {isFromMobile ? (
                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.7722 2.32775C15.0759 2.02401 15.0759 1.53154 14.7722 1.2278C14.4684 0.924065 13.9761 0.924065 13.6723 1.2278L8.00002 6.90012L2.32776 1.2278C2.02401 0.924065 1.53155 0.924065 1.22781 1.2278C0.924063 1.53154 0.924063 2.02401 1.22781 2.32775L6.90003 8.00001L1.22781 13.6723C0.924063 13.9761 0.924063 14.4684 1.22781 14.7722C1.53155 15.0759 2.02401 15.0759 2.32776 14.7722L8.00002 9.09999L13.6723 14.7722C13.9761 15.0759 14.4684 15.0759 14.7722 14.7722C15.0759 14.4684 15.0759 13.9761 14.7722 13.6723L9.1 8.00001L14.7722 2.32775Z"
                        fill="#011531"
                        fill-opacity="0.6"
                      />
                      <path
                        d="M1.1571 2.39846C0.814299 2.05567 0.814299 1.49988 1.1571 1.15709C1.49989 0.814302 2.05567 0.814303 2.39847 1.15709L2.39847 1.15709L8.00002 6.7587L13.6016 1.15709L13.6721 1.22764L13.6016 1.15709C13.9444 0.814303 14.5001 0.814303 14.8429 1.15709C15.1857 1.49988 15.1857 2.05567 14.8429 2.39846L9.24142 8.00001L14.8429 13.6016C15.1857 13.9444 15.1857 14.5001 14.8429 14.8429C14.5001 15.1857 13.9444 15.1857 13.6016 14.8429L8.00002 9.24141L2.39847 14.8429C2.05567 15.1857 1.49989 15.1857 1.1571 14.8429C0.8143 14.5001 0.8143 13.9444 1.1571 13.6016L6.75861 8.00001L1.1571 2.39846ZM1.1571 2.39846L1.1571 2.39846L1.22762 2.32794L1.1571 2.39846Z"
                        stroke="#011531"
                        stroke-opacity="0.6"
                        stroke-width="0.2"
                      />
                    </svg>
                  </div>
                ) : isFromTerm ? (
                  <div className="close-btn-term">
                    <div tabIndex={0} className="close-button">
                      <span className="hiddenText">close popup</span>
                      <div>{SvgIcon("close-icon")}</div>
                    </div>
                  </div>
                ) : (
                  "x"
                )}
              </div>
            )}
            <div className={"popup-inner " + childClassName}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
);

export default GenericPopup;
