import { useState, useContext } from "react";
import { getTodayDay, isRestaurantOpen, isPlaceOpen } from "../utils/utils";
import { ColorContrast } from "../utils/contexts";

export const OpeningHours = ({ openingHours }) => {
  const [hoursOpen, setHoursOpen] = useState(false);
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const today = getTodayDay();
  const isColorContrast = useContext(ColorContrast);
  // const isOpen = isRestaurantOpen(openingHours);
  const isOpen = isPlaceOpen(openingHours);

  return (
    <div>
      <button
        className="crouse-item-details accessibility-button "
        onClick={() => setHoursOpen(!hoursOpen)}
        aria-expanded={hoursOpen ? "true" : "false"}
      >
        <div className="hours-row">
          <div className="d-flex">
            <span className="mr-5">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="9"
                  cy="9"
                  r="8.25"
                  stroke="#011531"
                  stroke-width="1.5"
                />
                <path
                  d="M8.55005 4.5V9.9L13.05 12.15"
                  stroke="#011531"
                  stroke-width="1.5"
                />
              </svg>
            </span>
            Hours: &nbsp;{" "}
            <span className={`item-details-open-now-${isOpen}`}>
              {isOpen ? "Open" : "Closed"}
            </span>
          </div>
          <div>
            <img
              src={`/icons/arrow-${hoursOpen ? "up" : "down"}.svg`}
              alt=""
              className={isColorContrast ? "" : ""}
            ></img>
          </div>
        </div>
      </button>

      {/* {hoursOpen && ( */}
      <div
        role={"list"}
        className={"popup-item-opening-hours " + (hoursOpen ? "open" : "close")}
      >
        {days.map((d, i) => (
          <div
            role={"listitem"}
            key={i}
            className={
              "opening-day-row" +
              (today === d.toLowerCase() ? " selected-day-row" : "")
            }
          >
            <div className="opening-day-title">{d}</div>
            <div className="opening-day-time">
              {openingHours[d.toLowerCase()]}
            </div>
          </div>
        ))}
      </div>
      {/* )  } */}
    </div>
  );
};
