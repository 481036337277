import React from "react";
import Logo from "../images/logo-mobile.svg";
import MobileView from "../MobileView";

export default function Desktop() {
  return (
    <div className="main-desktop">
      <div className="container-desktop">
        <div className="header-logo">
          <img src={Logo} />
        </div>
        <div className="phone-screen">
          <div className="iframe-class">
            <MobileView />
          </div>
          {/* <iframe  src="./App.js"></iframe> */}
        </div>
      </div>
    </div>
  );
}
