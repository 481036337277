import React from "react";

export const MapEventsContext = React.createContext({
  onItemSelected: (item) => console.log(item),
  isDesktop: false,
  setPopup: console.log,
  setMapMode: console.log,
  selectedFooter: console.log,
});
export const ColorContrast = React.createContext(false);
