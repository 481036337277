import React, { useEffect } from "react";
import SvgIcon from "../Footer/SvgIcon";

const Header = ({
  onClick,
  isActiveHeader,
  setIsActiveHeader,
  activeIndex,
  setActiveIndex,
  isClick,
}) => {
  const headerData = [
    {
      svg: "coffee",
      title: "Coffee",
    },
    {
      svg: "lunch",
      title: "Lunch",
    },
    {
      svg: "drinks",
      title: "Drinks",
    },
    {
      svg: "shopping",
      title: "Shopping",
    },
    {
      svg: "culture",
      title: "Culture",
    },
    {
      svg: "outdoors",
      title: "Outdoor",
    },
  ];

  useEffect(() => {
    const handleScroll = (e) => {
      const coffeeRect = document
        ?.getElementById("Coffee")
        ?.getBoundingClientRect();
      const lunchRect = document
        ?.getElementById("Lunch")
        ?.getBoundingClientRect();
      const drinkRect = document
        ?.getElementById("Drinks")
        ?.getBoundingClientRect();
      const shoppingRect = document
        ?.getElementById("Shopping")
        ?.getBoundingClientRect();
      const cultureRect = document
        ?.getElementById("Culture")
        ?.getBoundingClientRect();
      const outdoorRect = document
        ?.getElementById("Outdoor")
        ?.getBoundingClientRect();
      if (window.innerWidth < 535) {
        if (lunchRect?.top > 135) {
          setActiveIndex(0);
          setIsActiveHeader("Coffee");
        } else if (lunchRect?.top < 136 && drinkRect?.top > 136) {
          setActiveIndex(1);
          setIsActiveHeader("Lunch");
        } else if (drinkRect?.top < 136 && shoppingRect?.top > 136) {
          setActiveIndex(2);
          setIsActiveHeader("Drinks");
        } else if (shoppingRect?.top < 136 && cultureRect?.top > 136) {
          setActiveIndex(3);
          setIsActiveHeader("Shopping");
        } else if (cultureRect?.top < 136 && outdoorRect?.top > 136) {
          setActiveIndex(4);
          setIsActiveHeader("Culture");
        } else if (outdoorRect?.top < 136) {
          setActiveIndex(5);
          setIsActiveHeader("Outdoor");
        }
      } else {
        if (lunchRect?.top > 275) {
          setActiveIndex(0);
          setIsActiveHeader("Coffee");
        } else if (lunchRect?.top < 280 && drinkRect?.top > 280) {
          setActiveIndex(1);
          setIsActiveHeader("Lunch");
        } else if (drinkRect?.top < 280 && shoppingRect?.top > 280) {
          setActiveIndex(2);
          setIsActiveHeader("Drinks");
        } else if (shoppingRect?.top < 280 && cultureRect?.top > 280) {
          setActiveIndex(3);
          setIsActiveHeader("Shopping");
        } else if (cultureRect?.top < 280 && outdoorRect?.top > 280) {
          setActiveIndex(4);
          setIsActiveHeader("Culture");
        } else if (outdoorRect?.top < 280) {
          setActiveIndex(5);
          setIsActiveHeader("Outdoor");
        }
      }
    };
    if (window?.innerWidth < 535) {
      window.addEventListener("scroll", (e) => {
        handleScroll();
      });
    } else {
      window.addEventListener("mousewheel", (e) => {
        handleScroll();
      });
    }

    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  }, [isClick, setActiveIndex, setIsActiveHeader]);

  return (
    <div className="crouse-menu new-navbar attraction-menu all-category">
      {headerData?.map((item, index) => (
        <div
          onClick={() => onClick(item.title, index)}
          className={`d-flex flex-column align-items-center  ${
            activeIndex === index ? "active" : ""
          } ${
            isActiveHeader === "Shopping" && item.title === "Shopping"
              ? "shopping-active"
              : ""
          } ${
            isActiveHeader === "Drinks" && item.title === "Drinks"
              ? "drinks-active"
              : ""
          }`}
          activeIndex
          id={index}
        >
          {SvgIcon(item.svg)}
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};

export default Header;
