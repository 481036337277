import { useState } from "react";
import { pdf } from "../apis/pdf";

export const useDownloadPdfs = (pages) => {
  const [pdfDown, setPdfDown] = useState(false);
  const [pdfLoading, setLoading] = useState(false);

  const pdfGuideArray = [];

  const downloadPdfs = async () => {
    if (!pdfLoading) {
      setLoading(true);
      setTimeout(async () => {
        const cities =
          pages && pages.length ? pages.map(({ title }) => title) : [];
        await await pdf({
          url: "multiple-pdfs",
          cities: cities.join(),
          guideDomain: "",
        });
        // await Promise.all(
        //   pages.map((crouseData) => {
        //     if (crouseData?.pageGuideURL) {
        //       try {
        //         return handlePdfDownload(crouseData).then((resp) => {
        //           // console.log('crouseData in useDownloadPdf file: ', crouseData)
        //           if (resp) {
        //             let alink = document.createElement("a");
        //             alink.href = resp;
        //             alink.download = `${crouseData.title}.pdf`;
        //             alink.click();
        //           }
        //         });
        //       } catch (ex) {
        //         pdfGuideArray.push(crouseData.title);
        //         console.log(ex);
        //         return true;
        //       }
        //     } else {
        //       pdfGuideArray.push(crouseData.title);
        //     }
        //   })
        // );
        if (pdfGuideArray?.length > 0) {
          alert(`${pdfGuideArray?.join()}: are missing an offline guide`);
          setLoading(false);
          setPdfDown(false);
        } else {
          setLoading(false);
          setPdfDown(true);
        }
      }, 0);
    }
  };

  return { pdfLoading, downloadPdfs, pdfDown };
};
