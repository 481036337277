import React from "react";
import "./skeleton.css";

const SkeletonLoader = () => {
  return (
    <div className="container">
      <div className="box">
        <div className="skeleton">
          <div className="">
            <div className="square h35 w35 m10"></div>
          </div>
          <div className="skeleton-right">
            <div className="square h35 w101 l10 m10"></div>
          </div>
        </div>
        <div className="skeleton">
          <div className="skeleton-left">
            <div className="square h79 w315 m10"></div>
          </div>
        </div>
        <hr className="p-t-b-23" />
        <div className="skeleton">
          <div className="skeleton-left">
            <div className="square h160 w315 m10"></div>
          </div>
        </div>
        <div className="skeleton">
          <div className="skeleton-left">
            <div className="square h26  w167 m10"></div>
          </div>
        </div>
        <div className="skeleton">
          <div className="skeleton-left">
            <div className="square h20 w167 m10"></div>
          </div>
        </div>
        <div className="skeleton">
          <div className="skeleton-left">
            <div className="square h20 w315 m10"></div>
          </div>
        </div>
        <div className="skeleton">
          <div className="skeleton-left">
            <div className="square h78 w315 m10"></div>
          </div>
        </div>
        <div className="skeleton">
          <div className="skeleton-left">
            <div className="square h40 w150 m10"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
