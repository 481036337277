import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import App from "./App";
import TermsPage from './pages/terms';

function ApplicationRoutes() {
    return <Router>
        <Routes>
            {/* <Route path="/share/item/:bundleId/:pageId" element={<ShareImage />} ></Route>
            <Route path="/share/:city/:pageSubTitle/:imageUrl?/:refTitle?" element={<ShareImageFromUrl />} ></Route> */}
            <Route path="/terms" element={<TermsPage />} ></Route>
            <Route path="/:linkedBundleId" element={<App />} ></Route>
            <Route path="/:linkedBundleId/:linkedCity" element={<App />} ></Route>
            <Route path="/:linkedBundleId/:linkedCity/:linkedLocation" element={<App />} ></Route>
            <Route path="*" element={<App />}></Route>
        </Routes>
    </Router>
}
export default ApplicationRoutes;
