import SignInBundleId from "./SignInBundleId";
import SignInPageList from "./SignInPageList";

const SignInCheck = (props) => {
  const {
    pdfDown,
    pdfLoading,
    downloadPdfs,
    message,
    setTermsPorpup,
    setBundle,
    setCrouseData,
    bundleData,
    closePopUp,
    crouseData,
    setMessage,
    passedCheck,
    allBundleData,
    setPassedCheck,
    loader,
  } = props;
  return (
    <div className="slider-mobile">
      {!passedCheck ? (
        <SignInBundleId
          setTermsPorpup={setTermsPorpup}
          loader={loader}
          message={message}
          setMessage={setMessage}
          crouseData={crouseData}
          setBundle={setBundle}
          bundleData={bundleData}
          closePopUp={closePopUp}
          setCrouseData={setCrouseData}
        />
      ) : (
        <SignInPageList
          pdfDown={pdfDown}
          pdfLoading={pdfLoading}
          downloadPdfs={downloadPdfs}
          setPassedCheck={setPassedCheck}
          passedCheck={passedCheck}
          closePopUp={closePopUp}
          setBundle={setBundle}
          allBundleData={allBundleData}
          bundleData={bundleData}
          crouseData={crouseData}
          setCrouseData={setCrouseData}
        />
      )}
    </div>
  );
};
export default SignInCheck;
