/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import {
  useContext,
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import SvgIcon from "../Footer/SvgIcon";
import { MapEventsContext, ColorContrast } from "../utils/contexts";

const MobilePopup = forwardRef((props, ref) => {
  const {
    children,
    showX = true,
    bgImage,
    focusOnFirstElement,
    isFromMapView,
    itemData,
  } = props;
  // let newCC;
  // if (center) {
  //   newCC = [center[0] - 0.0006, center[1]];
  // }
  const eventsContext = useContext(MapEventsContext);
  const isColorContrast = useContext(ColorContrast);

  const focusOCloseButton = useRef(null);

  useEffect(() => {
    if (focusOCloseButton.current) {
      focusOCloseButton.current.focus();
    }
  }, []);

  const handlePress = (e) => {
    if (e.key === "Tab" && e.shiftKey) {
      focusOnFirstElement();
    }
    if (e.key == "Enter") {
      console.log("4");
      eventsContext.setPopup(null);
    }
  };

  useImperativeHandle(ref, () => ({
    childFunction() {
      focusOCloseButton.current.focus();
    },
  }));

  return (
    <>
      <div
        className={` mobile-location-popup header-location attraction-popup`}
      >
        {showX && (
          <div className="close-btn-main mobile-popup-btn">
            <div
              tabIndex={0}
              onKeyDown={handlePress}
              ref={focusOCloseButton}
              className="close-button close-btn-inner"
              onClick={() =>
                eventsContext.setPopup(
                  isFromMapView ? itemData : null,
                  isFromMapView ? true : false,
                  false,
                  isFromMapView ? true : false,
                  true
                )
              }
            >
              <span className="hiddenText">close popup</span>
              <div>{SvgIcon("close-icon")}</div>
            </div>
          </div>
        )}
        <div
          class="modal fade"
          id="myModal"
          role="dialog"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className={isColorContrast ? "popup color-contrast" : "popup"}>
            <div
              className={
                isColorContrast ? "popup-inner color-contrast" : "popup-inner"
              }
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default MobilePopup;
